<template>
  <div class="home">
    <h1>Web site is in process of creation!</h1>
    <p>Check out my other projects:</p>
    <p>
      <a href="https://www.russianhope.com/" target="about_blank"
        >Maxim Vyshegorodtsev Photography</a
      >
    </p>
    <p>
      <a href="http://www.paritetdisk.ru/" target="about_blank"
        >ParitetDisk - my friend's company website</a
      >
    </p>
  </div>
</template>
